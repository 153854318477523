import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const WhiteOutlineBtn = styled(BaseButton)`		
	background:transparent;
	color:#fff;
	box-shadow: inset 0 0 1px 1px #fff;
	&:before{
		background-color:#ff4848;
	}
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		color:#fff;
		&:before{			
    		width: 100%;
		}
	}
`
const WhiteOutlineButton = (props) => {
	const { icon, iconAfter, text, size, iconspace } = props;
	return(
		<WhiteOutlineBtn className={`btn ${size ? `btn-${size}` : ''} ${iconspace ? `btn-${iconspace}` : ''}  `}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</WhiteOutlineBtn>
	)
}


export default WhiteOutlineButton

