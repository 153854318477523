import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { LineArrowRightIcon } from "../Icons";
import PrimaryButton from "../Button/PrimaryButton";
import {
  Form,
  FormSubTitle,
  FormTitle,
  FormRow,
  FormCol,
  FormTwoCol,
  FormThreeCol,
  FormAction,
} from "../Form";

const ProductForm = ({ location = "", popup }) => {
  const [fullname, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [height, setHeight] = useState("");
  const [message, setMessage] = useState("");
  let thanksURL = "";

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }, []);

  const handleSubmit = (e) => {
    let utm_source_val = "Website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    e.preventDefault();
    if (
      fullname !== "" &&
      phone !== "" &&
      email !== "" &&
      zipcode !== "" &&
      width !== "" &&
      length !== "" &&
      height !== "" &&
      message !== ""
    ) {
      const data = {
        source: location.origin,
        form: "Product form",
        page: location.pathname,
        first_name: fullname,
        phone_no: phone,
        email: email,
        zipcode: zipcode,
        width: width,
        length: length,
        height: height,
        comment: message,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
      };
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/v1/create-lead",
        data: data,
        headers: { Authorization: "Bearer " + process.env.GATSBY_API_TOKEN },
      })
        .then((res) => {          
          navigate('/thank-you');          
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  return (
    <>
      <FormSubTitle className="form-subtitle">Let’s Discuss</FormSubTitle>
      <FormTitle className="form-title h4">Your Metal Building Project</FormTitle>

      <form onSubmit={handleSubmit}>
        <Form className="form">
          <FormRow className="form-row">
            <FormTwoCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="text"
                  id="fullname"
                  className="form-control"
                  placeholder="Full Name"
                  pattern="^(?!\s*$)[A-Za-z\s]+$"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="tel"
                  id="phone_no"
                  className="form-control"
                  placeholder="Phone Number"
                  required
                  pattern="[0-9]{10}"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="email"
                  id="email"
                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                  className="form-control"
                  placeholder="Email ID"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="text"
                  id="zip_code"
                  pattern="^[0-9]{5}$"
                  className="form-control"
                  placeholder="Zip Code"
                  required
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>
            </FormTwoCol>

            <FormThreeCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="text"
                  id="width"
                  className="form-control"
                  placeholder="Width"
                  pattern="^[1-9]|[1-9][0-9]|[1-9][0-9][0-9]$"
                  required
                  onChange={(e) => setWidth(e.target.value)}
                />
              </div>
            </FormThreeCol>
            <FormThreeCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="text"
                  id="length"
                  className="form-control"
                  placeholder="Length"
                  pattern="^[1-9]|[1-9][0-9]|[1-9][0-9][0-9]$"
                  required
                  onChange={(e) => setLength(e.target.value)}
                />
              </div>
            </FormThreeCol>
            <FormThreeCol className="form-coltwo">
              <div className="form-group-md">
                <input
                  type="text"
                  id="height"
                  className="form-control"
                  placeholder="Height"
                  pattern="^[1-9]|[1-9][0-9]|[1-9][0-9][0-9]$"
                  required
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            </FormThreeCol>

            <FormCol>
              <div className="form-group-md">
                <textarea
                  name="review"
                  rows="3"
                  type="text"
                  id="message"
                  className="form-control"
                  placeholder="Enter Your Message"
                  required
                  minLength={10}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if (!e.target.value || e.target.value.trim() === "") {
                      e.target.setCustomValidity("Please fill out this field.");
                    } else {
                      e.target.setCustomValidity("");
                    }
                  }}
                ></textarea>
              </div>
            </FormCol>
          </FormRow>
          <FormAction className="form-action">
            <button type="submit" aria-label="button">
              <PrimaryButton
                iconspace="icon-space"
                text="Submit"
                iconAfter={<LineArrowRightIcon />}
              />
            </button>
          </FormAction>
        </Form>
      </form>
    </>
  );
};

export default ProductForm;
