import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const ColorPick = styled.div`
    position:relative;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content: space-between;
    background-color:#fff;
    padding:4px 20px;
    border-radius:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.15);
    margin:0;
    @media(min-width:1200px){
        box-shadow:none;
        border-radius:10px 10px 0 0;
        margin: -40px 20px 0 20px;

    }
`
const ColorLabel = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size:14px;
    line-height:24px;
    font-weight:500;
    color:#7C8DA2;    
`
const ColorList = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -6px;
    justify-content: center;
`
const ColorItem = styled.div`
    position:relative;
    width:100%;
    cursor:pointer;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding:6px;
    .icon{
        > svg{
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: transform;
        }
    }
    .code{
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: transform;
    }
    .code-name{
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        display: block;
      color:#535353
    }
    &:hover, &.selected{
        .icon{
            > svg{
                fill:#161420;
                transform: translateY(-5px) rotate(45deg);
            }
        }
        .code{
            transform: translateY(-5px);
        }
        .code-name{
            opacity: 1;
            transform: translate(-50%, -5px);
        }
    }
`
const ColorCode = styled.div`
    width:20px;
    height:20px;
    border-radius:50%;
    border:1px solid #fff;
    background-color:${props=>props.bkgColor};
    box-shadow:0 0 4px rgba(0,0,0,0.15);
    transition:all 0.5s ease-in-out;
    margin: 0 auto;
`
const ColorName = styled.div`
    color: #161420;
    text-align: center;
    letter-spacing: 0.5px;
    
    opacity: 0;
    transition-duration: 0.3s;
    transition-property: transform, opacity;
    font-size: 12px;
    line-height: 24px;
    > span{
        white-space: nowrap;
    }
`


const ColorPalettes = () => {
  const data = useStaticQuery(graphql`
    query ColorPalettesQuery {
      allContentfulColor(sort: {fields: order}) {
        edges {
          node {
            colorName
            colorHexValue
            coloredImage {
              gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
            }
            }
      }
    }
  `)
  const colors = data.allContentfulColor.edges;
  return (
    <ColorPick>
        <ColorLabel className="color-label">Colors Options:-</ColorLabel>
        <ColorList>
            {colors.map((item, i)=>
                <ColorItem key={i}>
                    <ColorCode className="code" bkgColor={item.node.colorHexValue}></ColorCode>
                    <ColorName className="code-name"><span>{item.node.colorName}</span></ColorName>
                </ColorItem>
            )}
        </ColorList>
  </ColorPick>
  )
}

export default ColorPalettes
